import { PropsWithChildren } from 'react'

import { Center, useToast } from '@masterplandev/ui'

import { AuthProvider } from '@/auth/components/AuthProvider/AuthProvider'
import Loader from '@/core/components/ui/Loader/Loader'
import useTranslation from '@/core/hooks/useTranslation'
import { inIframe } from '@/core/utils/inIframe'

import useScormAuth from '../../hooks/useScormAuth'
import { ScormIframeWarning } from '../ScormIframeWarning/ScormIframeWarning'

export function ScormAuthProvider({ children }: PropsWithChildren<object>) {
  const toast = useToast()
  const scormAuth = useScormAuth()
  const { translate } = useTranslation('scorm')

  // scorm can only be launched in an iframe
  if (!inIframe()) {
    return <ScormIframeWarning />
  }

  if (scormAuth.error) {
    toast({ status: 'error', title: translate('error.empty') })

    return null
  }

  if (!scormAuth.fetched) {
    return (
      <Center position="fixed" inset="0">
        <Loader />
      </Center>
    )
  }

  return (
    <AuthProvider
      initOptions={{
        token: scormAuth.data?.access_token,
        refreshToken: scormAuth.data?.refresh_token,
        checkLoginIframe: false,
      }}>
      {children}
    </AuthProvider>
  )
}
